import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  NavLink
} from "react-router-dom";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import Firebase from 'firebase';

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }

  const [currentUserData, setCurrentUserData] = React.useState();
  const [tr, setTR] = React.useState(false);  

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
          if(data && !data.currentplan){
            if(data && !data.currentplan){
              Firebase.database()
              .ref(`/webadmin/${user.email.replace(/\./g, ",")}/`).update({currentplan: "pay as you go", perdeliverycost: 0.15})
            }
          }
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])
  const choosePlan = (plan) => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 6000);
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}/currentplan`).set(plan)
  }
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
        <Snackbar
          place="tr"
          color="danger"
          message="Your plan changed, we will send you email on next steps"
          open={tr}
          closeNotification={() => setTR(false)}
          close
        />
          {/* <h2 className={classes.title}>Free Credits: 1000</h2> */}
          <h5 className={classes.description} color="rose">
            Currently you are on <b>{currentUserData?.currentplan?.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})}</b> plan, Look into billing section for outstanding amount
            {" "}<NavLink to={"/admin/billing"} href="#pablo">
              Billing
            </NavLink>
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={currentUserData?.currentplan == "pay as you go"}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>STARTER</h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Pay As You Go
              </h3>
              <p className={classes.cardDescription}>
               We charge $0.15/Delivery. You will be billed monthly based on usage
              </p>
              <Button round color="rose" onClick={()=>choosePlan('pay as you go')} disabled={currentUserData?.currentplan == "pay as you go"}>
                {currentUserData?.currentplan == "pay as you go" ? "Selected plan" : "Choose plan"}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={currentUserData?.currentplan == "monthly subscription"}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>ADVANCED</h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Subscription
              </h3>
              <p className={classes.cardDescription}>
              We charge $200/Month. Unlimited number of deliveries and delivery persons.
              </p>
              <Button round color="rose" onClick={()=>choosePlan('monthly subscription')} disabled={currentUserData?.currentplan == "monthly subscription"}>
                {currentUserData?.currentplan == "monthly subscription" ? "Selected plan" : "Choose plan"}
              </Button>
              <br/>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>ENTERPRISE</h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Custom Pricing
              </h3>
              <p className={classes.cardDescription}>
                Unlimited number of deliveries and delivery persons with API access
              </p>
              <Button round color="rose" disabled>
                Choose plan
              </Button>
              <br/>
              <small>Send us email info@onroad.app</small>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing >
              <h6 className={classes.cardCategory}>MEDIUM COMPANY</h6>
              <div className={classes.icon}>
                <Business className={classes.iconWhite} />
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $69
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is between 11 and 99 Persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>ENTERPRISE</h6>
              <div className={classes.icon}>
                <AccountBalance className={classes.iconWhite} />
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $159
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is 99+ persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
