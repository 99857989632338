import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Firebase from "firebase";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
};

const useStyles = makeStyles(styles);

export default function Functions() {
  const classes = useStyles();
  const [currentUserData, setCurrentUserData] = React.useState();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  React.useEffect(() => {
     const onChildAdd = Firebase.database()
       .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
       .on('value', snapshot => {
         const data = snapshot.val()
         if (data) {
           setCurrentUserData(data);
         }
       });
     return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
   }, [])
  const liveTrackExample = () => {
        return ['curl --request POST https://api.onroad.app/livetrackingurl', <br /> ,
               `--header Authorization: ${currentUserData?.apikey}`, <br/> ,
               '-- data-raw {',
               "'deliverypersonphone': '+16505551234'",
               "'customerphone': '+18622878740'",
               "'location': '{",
               "'address': '{", 
               "'lat': 25", 
               "'lng': 44",
               "}",
               "}'", <br/>
              ];

  }

  const routeOptimizationExample = () => {
    return ['curl --request POST https://api.onroad.app/routeoptimization', <br/> ,
      `--header Authorization: ${currentUserData?.apikey}`, <br/> ,
      '-- data-raw {',
      '"startingpoint": {\
        "address": "100 rio robles, san jose, CA",\
        "lat": 37.7403451,\
        "lng": -121.9473497\
      },\
      "listofdeliveries": [{\
        "address": "520 linford place san ramon, CA",\
        "lat": 37.764536,\
        "lng": -121.9646017\
      }, {\
        "address": "34 canyonwoods cirle, san ramon, CA",\
        "lat": 37.7657962,\
        "lng": -121.9517954\
      }]',
      "}'", <br/>
    ];

  }
  
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/api/routeoptimization/${user.email.replace(/\./g,",")}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            result.push([element.isscheduled.toString(), element.routeoptimizationresult, element.totaldeliveries, element.totaldeliverypersons])
          }
          setData(result);
        }

      });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])  

  return (
    <div>
      <Heading
        textAlign="center"
        title="Settings"
        
      />
      <Card>
        <CardBody>
          <div className={classes.typo}>
            <div className={classes.note}>Tracking App</div>
            <Quote
              style={{fontSize: "10px"}}
              
              author = "Onroad mobile app"
            />
          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Database</div>
            <Button size="sm" onClick={() => {console.log("==provision database==")}} color="primary" disabled>Create Redis</Button>
            {/* <Quote
              style={{fontSize: "10px"}}
              
              author = "Onroad Database"
            /> */}
          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Provision Own Database</div>
            <Quote
              style={{fontSize: "10px"}}
              text={""}
              author = "Coming soon..."
            />
          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Webhooks</div>
            <Quote
              style={{fontSize: "10px"}}
              text={""}
              author = "Coming soon..."
            />
          </div>
          
          <div className={classes.typo}>
            <div className={classes.note}>How to use?</div>
            <Danger>
              Please send an email to hari@onroad.app. For more details
            </Danger>
          </div>
        </CardBody>
      </Card>
      {/* <Heading
        textAlign="center"
        title="Usage"
      />
      <Card>
        <CardBody>
          {
            data ? (< Table
              tableHeaderColor="primary"
              tableHead = {["Is scheduled", "Route optimization result", "Total Deliveries", "Total Delivery Persons"]}
              tableData={data}
              coloredColls={[3]}
              colorsColls={["primary"]}
          />) : (<Heading
                textAlign = "center"
                title = "No API Usage Yet"
                />)
          }
        </CardBody>
      </Card> */}
    </div>
  );
}
