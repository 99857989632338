import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Firebase from "firebase";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Schedule from "@material-ui/icons/Schedule";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Accordion from "components/Accordion/Accordion.js";
import CardHeader from "components/Card/CardHeader.js";
import Divider from "@material-ui/core/Divider";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StorageIcon from '@material-ui/icons/Storage';

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
};

const useStyles = makeStyles(styles);

export default function Databases() {
  const classes = useStyles();
  const [currentUserData, setCurrentUserData] = React.useState();
  const [createDatabaseModal, setCreateDatabaseModal] = React.useState(false);
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  React.useEffect(() => {
     const onChildAdd = Firebase.database()
       .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
       .on('value', snapshot => {
         const data = snapshot.val()
         if (data) {
           setCurrentUserData(data);
         }
       });
     return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
   }, [])

  const createDatabase = () => {
    
    setCreateDatabaseModal(false)
   }
  return (
    <>
      <GridContainer>
        <Dialog open={createDatabaseModal} aria-labelledby="form-dialog-title" fullWidth keepMounted>
        <DialogTitle id="form-dialog-title">Create A Database</DialogTitle>
        <DialogContent>
          <FormControlLabel
              control={
                <Radio
                  checked={true}
                  aria-label="B"
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    disabled: classes.disabledCheckboxAndRadio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
                label={
                  (<>
                    <p style={{ fontWeight: "bolder", color: "black"}}>
                      {/* $15/mo ($0.022/hr) */}
                      Primary Node
                    </p>
                  <p>
                      Basic / 1 vCPU / 1 GB RAM / 15 GB SSD
                  </p>
                  </>)
                }
            /> 
            <br />
            <br />
            <DialogContentText>
              Totla Monthly Cost
            </DialogContentText>
            <div style={{backgroundColor: "#f3f5f9", paddingLeft: "7%",  paddingTop: "20px", backgroundClip: "border-box", paddingRight: "20px"} }>
             <GridContainer>
                <GridItem xs={1} sm={1} md={1}>
                  <StorageIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>Primary node</span>
                    <p>
                    Basic / 1 vCPU / 1 GB RAM / 15 GB SSD
                    </p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  $15.00
                </GridItem>
                <br />
               
                <Divider style={{border: "0", clear:"both", display:"block", width: "96%", height: "1px"}} />
               
               
                <br />

                <GridItem xs={1} sm={1} md={1}>
                  <LocalOfferIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>Total cost</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                    <span style={{fontWeight: "900"}}>$15.00/month</span>
                    <p>$0.022/hour</p>
                </GridItem>                
              </GridContainer>
            </div>
            <DialogContentText>
             <p style={{fontSize: "12px"}}>Location data will be stored in this database. You can directly connect to it from your application</p>
            </DialogContentText>
       

        </DialogContent>
          <DialogActions>
          <Button size="sm" onClick={() => setCreateDatabaseModal(false)} color="danger" >
          Close
        </Button>
        <Button  size="sm" onClick={() => createDatabase()} color="primary" disabled>
          Create Redis
        </Button>
        </DialogActions>
        </Dialog>
        <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.cardTitle}>
                Logistics Cloud <small> - Database & Cloud Functions</small>
              </h4>
              <NavPills
                color="warning"
                tabs={[
                  {
                    tabButton: "Resources",
                    tabContent: (
                      <>
                        <Card>
                          <CardHeader>
                            <h4 className={classes.cardTitle}>
                              <small>Onroad default database & functions</small>
                            </h4>
                          </CardHeader>
                          <CardBody>
                          <div className={classes.typo}>
                            <div className={classes.note} style={{maxWidth: "50%"}}>
                              Location Database
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>OnRoad's Fast database. You can only access through API</small>
                              </span>
                            </div>
                            
                            </div>
                            <div className={classes.typo}>
                            <div className={classes.note} style={{maxWidth: "50%"}}>
                              Functions
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{textTransform: "capitalize"}}>
                               <small style={{color: "gray"}}>Track Summary</small>
                              </span>
                             </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{textTransform: "capitalize"}}>
                              <small style={{color: "gray"}}>Hourly Track Summary</small>
                              </span>
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{textTransform: "capitalize"}}>
                               <small style={{color: "gray"}}>Time Distribution</small>
                              </span>
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{textTransform: "capitalize"}}>
                               <small style={{color: "gray"}}>Timeline Distribution</small>
                              </span>
                            </div>
                            
                            </div>
                            <small style={{color: "red", paddingLeft: "30%"}}>You can delete OnRoad's database & functions once you create your own</small>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h4 className={classes.cardTitle}>
                              <small>Create your own database & functions</small>
                            </h4>
                          </CardHeader>
                          <CardBody>
                          <div className={classes.typo}>
                            <div className={classes.note} style={{maxWidth: "50%"}}>
                              Location database
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                              <Button size="sm" onClick={() => {setCreateDatabaseModal(true)}} color="primary" disabled>Create A Redis</Button>
                              </span>
                            </div>
                           </div>
                          <div className={classes.typo}>
                            <div className={classes.note} style={{maxWidth: "50%"}}>
                              Cloud Functions
                            </div>
                            <div style={{width: "100%", position: "relative"}}>
                              <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                              <Button size="sm" onClick={() => {console.log("==provision database==")}} color="primary" disabled>Deploy A Function</Button>  <span style={{position: "relative", color: "gray", position: "relative", fontSize: "9px"}}>Coming Soon</span>
                              </span>
                            </div>
                          </div>
                          </CardBody>
                        </Card>
                      </>
                   
                    ),
                  },
                  // {
                  //   tabButton: "Activity",
                  //   tabContent: (
                  //     <span>
                  //       <p>
                  //         Efficiently unleash cross-media information without
                  //         cross-media value. Quickly maximize timely
                  //         deliverables for real-time schemas.
                  //       </p>
                  //       <br />
                  //       <p>
                  //         Dramatically maintain clicks-and-mortar solutions
                  //         without functional solutions.
                  //       </p>
                  //     </span>
                  //   ),
                  // },
                  // {
                  //   tabButton: "Settings",
                  //   tabContent: (
                  //     <span>
                  //       <p>
                  //         Completely synergize resource taxing relationships via
                  //         premier niche markets. Professionally cultivate
                  //         one-to-one customer service with robust ideas.{" "}
                  //       </p>
                  //       <br />
                  //       <p>
                  //         Dynamically innovate resource-leveling customer
                  //         service for state of the art customer service.
                  //       </p>
                  //     </span>
                  //   ),
                  // },
                ]}
              />
           
        </GridItem>
       
      </GridContainer>
   
    </>
  );
}
