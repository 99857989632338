import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";

const useStyles = makeStyles(styles);

export default function Timeline(props) {
  const classes = useStyles();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple,
    });
  return (
    <ul className={timelineClass}>
      {stories?.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple,
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple,
          });
        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <>
              {prop.timeStamp && (<h4 className={classes.timelineTime}>{prop.timeStamp}</h4>)}
              {prop.userTimeStamp && (<h4 className={classes.userTimeStamp}><span>{prop.userTimeStamp.split(" ")[0]}</span><br/><span>{prop.userTimeStamp.split(/ (.*)/)[1]}</span></h4>)}

              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
              </>
            ) : null}
            <div className={panelClasses}>
              {prop.title ? (
                <div className={classes.timelineHeading}>
                  <Badge color={prop.titleColor}>{prop.title}</Badge>
                </div>
              ) : null}
              <div className={classes.timelineBody}>
                {prop.img && (<img src={prop.img} title="" style={{width: "100%"}}/>)}
                {prop.mapimage && (<img src={prop.mapimage} title="" style={{ width: "100%" }} />)}
                {prop.timespent ? ( <GridContainer >
                  <GridItem xs={9} sm={9} md={9}>
                    {prop.body}
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    {prop.timespent == 'showspinner' ? <CircularProgress /> : <h4>{prop.timespent}</h4> }
                  </GridItem>
                  </GridContainer>) : (<>{prop.body}</>)}
                {/* <div dangerouslySetInnerHTML={{ __html: prop.body}} /> */}
                
              </div>
              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};
